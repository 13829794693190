/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from '~/shared/ui'
import { SVGDone } from '~/shared/ui/SVG/SVGDone'
import { Suggestions } from '~/shared/api/services/registration/types'
import { useStore } from '~/app/store'

const DadataRow = ({ title, text }: { title: string; text: string }) => {
  return (
    <div className="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row">
      <div className="min-w-[200px] p-[6px] sm:p-[6px] md:p-2 lg:p-2 xl:p-2">
        {title}
      </div>
      <div className=" text-sm p-[6px] sm:p-[6px] md:p-2 lg:p-2 xl:p-2 text-[#707070]">
        {text}
      </div>
    </div>
  )
}

export const DadataInfo = (
  props: {
    setAgree: Dispatch<SetStateAction<boolean>>
    agree: boolean
    setDadata: Dispatch<SetStateAction<Suggestions | null>>
  } & Suggestions,
) => {
  const { unrestricted_value, data, setAgree, setDadata, agree } = props
  const { registrationStore } = useStore()
  const [serverError, setServerError] = useState<string | null>(null)

  return (
    <>
      <div
        className={`border ${
          serverError ? 'border-red-500 ' : 'border-gray-200'
        } p-2 rounded-lg`}
      >
        <p className="flex justify-center pb-3 text-[12px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[18px]">
          Заполнение реквизитов по ИНН
        </p>
        <hr className="hr" />
        <DadataRow title="Краткое название:" text={unrestricted_value} />
        <DadataRow title="ИНН:" text={data.inn} />
        <DadataRow title="КПП:" text={data.kpp} />
        <DadataRow
          title="Адрес регистрации:"
          text={data.address.unrestricted_value}
        />
        <DadataRow
          title="ФИО руководителя:"
          text={data.management?.name || 'Отсутствует'}
        />
      </div>
      {serverError && (
        <p className="text-red-500 text-sm mt-2">{serverError}</p>
      )}
      <div className="flex">
        {!agree && (
          <>
            <Button
              size="small"
              className="flex items-center justify-center"
              onClick={() => {
                registrationStore
                  .validate({
                    legalEntity: {
                      inn: data.inn,
                    },
                  })
                  .then((error) => {
                    if (!error) {
                      setAgree(true)
                      setServerError(null)
                    } else {
                      setServerError(
                        error['legalEntity.inn'] || error.defaultError,
                      )
                    }
                  })
                  .catch((error: any) => {
                    setServerError(error.toString().replace(/^Error:\s*/, ''))
                  })
              }}
              disabled={!!serverError}
            >
              <SVGDone color="white" size="20px" />
              Подтвердить
            </Button>
            <Button
              intent="secondary"
              size="small"
              className="flex items-center justify-center ml-1"
              onClick={() => {
                setAgree(false)
                setDadata(null)
              }}
            >
              Отменить
            </Button>{' '}
          </>
        )}
      </div>
    </>
  )
}

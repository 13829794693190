import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import css from 'classnames'
import { useStore } from '~/app/store'
import {
  OrderByType,
  OrdersContainer,
} from '~/shared/api/services/warehouses/types'
import { Button } from '~/shared/ui'
import { FilterSVG } from '~/shared/ui/SVG/SVGFilter'
import Spinner from '~/shared/ui/spinner'
import GoToTop from '../scroll-to-top'
import { IntentType } from './types'

export const AssemblyByType = observer(() => {
  const { warehousesStore } = useStore()
  const { getAssembly, assembly, loaders } = warehousesStore
  const { type } = useParams<{ type: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const listBottom = useRef<HTMLDivElement>(null)
  const dateRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

  const anchor = useMemo(
    () => (
      <div ref={listBottom} className={css({ hidden: loaders.assembly })} />
    ),
    [loaders.assembly],
  )
  useEffect(() => {
    getAssembly(type)
  }, [type, getAssembly])

  const toggleFilter = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    const scrollToDate = (date: string) => {
      const targetRef = dateRefs.current ? dateRefs.current[date] : null

      if (targetRef) {
        targetRef.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement

      if (target && target.id) {
        scrollToDate(target.id)
      }
      if (
        !(target as HTMLElement).closest('.filter-button') &&
        !(target as HTMLElement).closest('.filter-content')
      ) {
        setIsOpen(false)
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const renderOrdersByType = () => {
    if (!assembly || !assembly.ordersData || !assembly.ordersData.orders) {
      return <Spinner />
    }

    const ordersData = assembly.ordersData.orders
    const { platform, marketplaceOrders } = ordersData as OrdersContainer

    if (type === 'platform' || type === 'retail') {
      if (Array.isArray(platform) && platform.length > 0) {
        return (
          <div className="flex flex-wrap justify-between">
            {platform.map((order: OrderByType, index: number) => (
              <Button
                badgeContent={order.badge}
                key={order.id || index}
                intent={order.style as IntentType}
                className="w-[140px] h-[48px] mb-[20px]"
              >
                {order.number}
              </Button>
            ))}
          </div>
        )
      }
    } else if (marketplaceOrders && typeof marketplaceOrders === 'object') {
      return (
        <div className="flex flex-col">
          {Object.entries(marketplaceOrders).map(([date, orders]) => {
            if (Array.isArray(orders)) {
              return (
                <div
                  key={date}
                  ref={(el) => {
                    if (el) dateRefs.current[date] = el
                  }}
                >
                  <p className="mb-2">{date}</p>
                  <div className="flex justify-between flex-wrap">
                    {orders.map((order: OrderByType, index: number) => (
                      <Button
                        badgeContentMarketplace={order.badge}
                        key={order.id || index}
                        intent={order.style as IntentType}
                        className="w-[140px] h-[48px] mb-[20px] rounded-lg"
                      >
                        {order.number}
                      </Button>
                    ))}
                  </div>
                </div>
              )
            }
            return null
          })}
        </div>
      )
    }
    return (
      <div className=" text-[#242424] font-normal text-[18px]">
        {' '}
        Заказы отсутствуют{' '}
      </div>
    )
  }

  const renderDateFilters = () => {
    const ordersData = assembly?.ordersData?.orders

    if (!ordersData || !('marketplaceOrders' in ordersData)) return null

    const uniqueDates = Object.keys(ordersData.marketplaceOrders || {})
    return (
      <div className="w-[180px] max-h-[200px] bg-white absolute top-[108px] right-5 z-50 rounded-lg flex flex-col gap-2 py-1 px-2 overflow-y-auto">
        {uniqueDates.map((date: string) => (
          <div
            key={date}
            className="hover:bg-slate-100 rounded-lg py-2 px-2 relative group cursor-pointer"
            id={date}
          >
            {date}
            <span className="hidden group-hover:block absolute top-[13px] left-0 h-[15px] w-[3px] bg-blue-600 rounded-lg" />
          </div>
        ))}
      </div>
    )
  }

  const hasFilterableOrders = Boolean(
    typeof assembly?.ordersData?.orders === 'object' &&
      ('marketplaceOrders' in assembly.ordersData.orders ||
        'marketplaceOrdersOrion' in assembly.ordersData.orders),
  )

  return (
    <>
      <div className="relative">
        <div className="flex justify-between mb-6 items-center">
          <h1 className="text-[20px] font-medium">Сборка {type}</h1>
          {hasFilterableOrders && type && (
            <div
              className="w-[32px] h-[32px] bg-white border border-gray-200 flex justify-center items-center rounded-lg"
              onClick={toggleFilter}
            >
              <FilterSVG size="24px" color="#242424" />
            </div>
          )}
        </div>
        {renderOrdersByType()}
      </div>
      {anchor}
      <div className="fixed bottom-8 left-7">
        <GoToTop />
      </div>

      {isOpen && renderDateFilters()}
    </>
  )
})

import { FC, useEffect } from 'react'
import css from 'classnames'
import { observer } from 'mobx-react-lite'
import { Card } from '..'
import { PropsModalWindow } from './type'
import '../../../app/styles/index.css'

export const ModalWindow: FC<PropsModalWindow> = observer(
  ({ isOpen, onClose, maxWidth, children }) => {
    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }

      return () => {
        document.body.classList.remove('no-scroll')
      }
    }, [isOpen])
    return (
      <div
        className={css(
          'items-center justify-center fixed top-0 left-0 right-0  z-[9999] p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full',
          { flex: isOpen },
          { hidden: !isOpen },
        )}
      >
        <div
          className="fixed right-0 overflow-y-hidden left-0 bottom-0 top-0 bg-black opacity-50 z-[9999]"
          onClick={onClose}
        />

        <div
          className={`relative  ${
            maxWidth ? 'max-w-[65%]' : 'w-[600px]'
          }  max-h-full z-[9999]`}
        >
          <Card> {children}</Card>
        </div>
      </div>
    )
  },
)

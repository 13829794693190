/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable } from 'mobx'
import FingerPrint from '@fingerprintjs/fingerprintjs'
import { AuthService } from '~/shared/api'
import {
  REFRESH_TOKEN_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  USER_STORAGE_KEY,
} from './config'
import { Handler, parseJSON } from '~/shared/lib'
import { ListUser, User } from '~/shared/api/services/users/types'

export class AuthModel {
  sessionToken = ''

  isLoading = false

  usersList: ListUser[] = []

  userById = null

  isListLoading = false

  isMoreLoading = false

  offset = 0

  limit = 20

  isEndUserList = false

  user: User | null = parseJSON(localStorage.getItem(USER_STORAGE_KEY)) || null

  tempHandlers: Handler[] = []

  constructor() {
    makeAutoObservable(this)

    this.getSessionTokenFromStorage()
  }

  setTempHandlers = (data: any) => {
    this.tempHandlers = data
  }

  get isAuth() {
    return !!this.sessionToken
  }

  private saveSessionTokenToStorage = (value: string) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, value)
    this.sessionToken = value
  }

  private saveUserToStorage = (value: User) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(value))
    this.user = value
  }

  private getSessionTokenFromStorage = async () => {
    const value = localStorage.getItem(TOKEN_STORAGE_KEY)

    if (value !== null) {
      this.sessionToken = value
    }
  }

  private setSessionToken = (token: string) => {
    this.saveSessionTokenToStorage(token)
    this.sessionToken = token
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setProfile = (user: User) => {
    this.saveUserToStorage(user)
  }

  logout = async () => {
    try {
      const { status } = await AuthService.logout()

      if (status) {
        this.setSessionToken('')
        localStorage.clear()
      }
    } catch (error) {
      throw new Error('Unsuccessfully logout!')
    }
  }

  login = async (email: string, password: string) => {
    this.setIsLoading(true)

    try {
      const fpPromise = FingerPrint.load()
      const fp = await fpPromise
      const res = await fp.get()

      const payload = {
        email,
        password,
        platform: 'web',
        deviceId: res.visitorId,
      }
      const { data } = await AuthService.login(payload)

      this.setSessionToken(data.token)
      this.saveUserToStorage(data.user)
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken)
    } catch (error: unknown) {
      throw new Error('Invalid user data! Try again!')
    } finally {
      this.setIsLoading(false)
    }
  }
}

export default AuthModel

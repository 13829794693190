/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/app/store'
import { Button, Card, DeleteSVG } from '~/shared/ui'
import { Input } from '~/shared/ui/input'
import { UserRole } from '~/features/roles'
import { UnlockSVG } from '~/shared/ui/SVG/unlockSVG'
import Spinner from '~/shared/ui/spinner'
import { ModalDeleteUser } from '~/features/user-edit/modal-delete'
import { BlocketSVG } from '~/shared/ui/SVG/blockedSVG'
import { Handler, formatErrorForToast, useForm, useToast } from '~/shared/lib'

export const UserEdit = observer(() => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const { id } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const toast = useToast()

  const { editedUser, loaders, getUsersById } = usersStore

  const [isShowPassword, setIsShowPassword] = useState({
    password: false,
    newPassword: false,
  })

  const navigate = useNavigate()
  useEffect(() => {
    if (id) {
      getUsersById(id)
    }
    return () => {
      usersStore.resetUser('editedUser')
      usersStore.setTempHandler([])
    }
  }, [getUsersById, id, usersStore])

  const togglePasswordVisibility = (type: keyof typeof isShowPassword) => {
    setIsShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }))
  }

  const {
    handleSubmit,
    handleChange,
    errors,
    data: formData,
  } = useForm({
    initialValues: {
      name: '',
      password: '',
      newPassword: '',
    },
    validations: {
      name: {
        custom: {
          isValid: (value) => value.trim().length >= 1 && !/^\s/.test(value),
          message: 'Введите имя пользователя',
        },
      },
      password: {
        custom: {
          isValid: (value) => !value || value.length >= 8,
          message: 'Пароль должен содержать не менее 8 символов',
        },
      },
      newPassword: {
        custom: {
          isValid: (value, values) => !value || value === values.password,
          message: 'Пароли должны совпадать',
        },
      },
    },
    onSubmit: async (data) => {
      try {
        if (usersStore.editedUser?.name !== data?.name && id) {
          await usersStore.editUser(id, data.name)

          await usersStore.getUsers('init')
          toast.open('Профиль успешно отредактирован!', 'success')
        }
        if (id && data.newPassword) {
          await usersStore.changePassword(id, data.newPassword)
          toast.open('Пароль успешно обновлен!', 'success')
        }
        usersStore.resetUser('editedUser')
        navigate('/settings')
      } catch (error: any) {
        toast.open(formatErrorForToast(error), 'danger')
      }
    },
  })

  useEffect(() => {
    // так как хук вызвался на пустых данных то перебрасываем данные
    if (editedUser?.name && !formData.name && handleChange) {
      const formHandler = handleChange('name')

      // Создаем элемент input
      const input = document.createElement('input')
      input.name = 'name'
      input.value = editedUser?.name

      // Создаем объект event.target с нужной структурой
      const syntheticEvent = {
        target: {
          name: 'name',
          value: editedUser?.name,
        },
      } as unknown as ChangeEvent<HTMLInputElement & HTMLSelectElement>

      // Вызываем хендлер изменения с синтетическим событием
      formHandler(syntheticEvent)
    }
  }, [editedUser, formData, handleChange])

  useEffect(() => {
    if (handleSubmit) {
      const tempHandler = handleSubmit as Handler
      usersStore.setTempHandler([tempHandler])
    }
  }, [usersStore, handleSubmit])
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex gap-4">
        <Card className="flex w-[400px] h-full flex-col gap-4">
          <p className="text-[20px] text-[#242424]">Информация профиля</p>

          <Input
            className="rounded"
            label={t('name')}
            type="text"
            id="nameEditUser"
            onChange={handleChange('name')}
            value={formData?.name ?? ''}
            labelType="floating"
            helperText={errors.name}
            status={errors.name ? 'error' : undefined}
            disabled={usersStore.isListLoading}
          />
          <Input
            className="rounded"
            label={t('email')}
            type="email"
            intent="disabled"
            value={editedUser?.email ?? ''}
            id="email"
            readOnly
            autoComplete="new-email"
            labelType="floating"
          />

          <p className="text-[20px] text-[#242424]">{t('password')}</p>
          <Input
            name="password"
            label={t('password')}
            type={isShowPassword.password ? 'text' : 'password'}
            onChange={handleChange('password')}
            id="password"
            labelType="floating"
            helperText={errors.password}
            status={errors.password ? 'error' : undefined}
            showPassword={() => togglePasswordVisibility('password')}
            autoComplete="new-password"
          />
          <Input
            name="newPassword"
            label="Пароль еще раз"
            type={isShowPassword.newPassword ? 'text' : 'password'}
            onChange={handleChange('newPassword')}
            id="newPassowrd"
            labelType="floating"
            helperText={errors.newPassword}
            status={errors.newPassword ? 'error' : undefined}
            showPassword={() => togglePasswordVisibility('newPassword')}
            autoComplete="new-password"
          />
          {editedUser && (
            <div>
              <div className=" pt-4">
                {editedUser?.enabled ? (
                  <div className="flex justify-center">
                    {' '}
                    <Button
                      intent="danger"
                      className="w-[190px]"
                      onClick={async () => {
                        try {
                          if (editedUser.id)
                            await usersStore.blockUser(editedUser.id, false)
                          toast.open('Профиль успешно заблокирован!', 'success')
                        } catch (error: any) {
                          toast.open(formatErrorForToast(error), 'danger')
                        }
                      }}
                    >
                      <BlocketSVG color="white" />
                      Заблокировать
                    </Button>
                  </div>
                ) : (
                  <div className="flex justify-between">
                    <Button
                      intent="yellow"
                      className="w-[190px]"
                      onClick={async () => {
                        try {
                          if (editedUser?.id)
                            await usersStore.blockUser(editedUser.id, true)
                          toast.open(
                            'Профиль успешно разблокирован!',
                            'success',
                          )
                        } catch (error: any) {
                          toast.open(formatErrorForToast(error), 'danger')
                        }
                      }}
                    >
                      <UnlockSVG color="white" /> Разблокировать
                    </Button>
                    <Button
                      intent="danger"
                      className="w-[150px]"
                      onClick={() => setOpenModal(true)}
                    >
                      {loaders.userDelete ? (
                        <Spinner />
                      ) : (
                        <DeleteSVG color="white" />
                      )}
                      Удалить
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Card>
        <UserRole />
      </div>
      <ModalDeleteUser
        isOpen={openModal}
        id={editedUser?.id}
        onClose={(e) => {
          e.preventDefault()
          setOpenModal(false)
        }}
      />
    </form>
  )
})

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/app/store'
import { Card, Checkbox } from '~/shared/ui'
import { Input } from '~/shared/ui/input'
import { PermissionsMap } from '~/features/role-add'

export const RoleEdit = observer(() => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const {
    validationErrors,
    setEditRole,
    roleById,
    selectedPermissions,
    setSelectedPermissions,
    permissions,
    isLoading,
  } = usersStore

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditRole(e.target.value)
  }
  const typedPermissions = permissions as unknown as PermissionsMap

  return (
    <div className="flex gap-4">
      {!isLoading && roleById && (
        <Card className="flex w-[100%] h-full flex-col gap-4">
          <p className="text-[20px] text-[#242424]">Информация профиля</p>

          <Input
            className="rounded"
            label={t('name')}
            value={roleById?.name}
            onChange={handleChange}
            labelType="floating"
            helperText={validationErrors['user.name']}
            status={validationErrors['user.name'] ? 'error' : undefined}
          />

          <div />

          <p className="text-[20px] text-[#242424] pt-4">Разрешения</p>
          {typedPermissions &&
            Object.entries(typedPermissions).map(([key, values]) => (
              <div key={key}>
                <p className="font-bold pb-4">{key}</p>
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 gap-4">
                  {values.map((item: any) => (
                    <div
                      className="flex items-center gap-2"
                      key={item.displayName}
                    >
                      <Checkbox
                        checked={selectedPermissions.includes(
                          item.originalName,
                        )}
                        label={item.displayName}
                        onClick={(event) => {
                          const targetElement = event.target as HTMLElement
                          if (targetElement.tagName === 'LABEL') {
                            event.preventDefault()
                          }
                          setSelectedPermissions(item.originalName)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </Card>
      )}
      {!isLoading && !roleById && 'Роле не найдена'}
    </div>
  )
})

import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { MainService } from '~/shared/api/services/main'
import { parseJSON } from '~/shared/lib'
import { STORES_STORAGE_KEY } from './config'
import {
  FiltersBySelect,
  Store,
  Loaders,
  Pages,
} from '../api/services/global/types'
import { GlobalService } from '../api/services/global'

export class GlobalModel {
  loading = false

  loaders: Loaders = {
    filters: false,
    categories: false,
  }

  stores: Store[] | [] =
    parseJSON(localStorage.getItem(STORES_STORAGE_KEY)) || []

  filtersBySelect: FiltersBySelect = {
    state: [
      { id: 'created', name: 'created' },
      { id: 'assembly', name: 'assembly' },
      { id: 'dispatch', name: 'dispatch' },
      { id: 'shipped', name: 'shipped' },
      { id: 'payment invoice', name: 'payment invoice' },
      { id: 'cancel', name: 'cancel' },
      { id: 'completed', name: 'completed' },
    ],
    store: parseJSON(localStorage.getItem(STORES_STORAGE_KEY)) || [],
  }

  requestSettings = {
    limit: 20,
    offset: 20,
  }

  requestSettingsCategories = {
    limit: 20,
    offset: 20,
  }

  filtersQ = { orderPage: '', productPage: '', marketingPage: '' }

  isEndCategoryList = false

  constructor() {
    makeAutoObservable(this)
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  setFiltersQ = (pageFilter: keyof Pages, value: string) => {
    this.filtersQ = { ...this.filtersQ, [pageFilter]: value }
  }

  getStores = async () => {
    try {
      this.loading = true
      const { data } = await MainService.getStores()

      runInAction(() => {
        this.stores = data
        this.filtersBySelect = { ...this.filtersBySelect, store: data }
      })
      localStorage.setItem(STORES_STORAGE_KEY, JSON.stringify(data))

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getFilters = async (
    names: Array<'products' | 'payments' | 'shipping'>,
    q?: string,
    loadMore?: boolean,
  ) => {
    this.activeLoader('filters')
    if (!loadMore) {
      this.requestSettings.offset = 0
      this.requestSettings.limit = 20
    }
    try {
      const params: Record<string, number | string> = {
        limit: this.requestSettings.limit,
        offset: this.requestSettings.offset,
      }
      if (q?.trim()) {
        params.q = q
      }
      const filterPromises = names.map(async (name) => {
        const { data } = await GlobalService.getFilters(name, { params })
        const key = (
          name === 'payments' ? 'payment' : name
        ) as keyof FiltersBySelect
        runInAction(() => {
          this.filtersBySelect = {
            ...this.filtersBySelect,
            [key]: loadMore
              ? [...(this.filtersBySelect[key] ?? []), ...data]
              : data,
          }
          this.requestSettings.offset += this.requestSettings.limit
          this.requestSettings = {
            ...this.requestSettings,
          }
        })
      })
      await Promise.all(filterPromises)
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('filters')
      })
    }
  }

  getCategories = async (action: 'reset' | 'loadMore', page: keyof Pages) => {
    if (this.isEndCategoryList && action === 'loadMore') return

    if (['reset'].includes(action)) {
      this.requestSettingsCategories.offset = 0
      this.requestSettingsCategories.limit = 20
    }

    runInAction(() => {
      this.activeLoader('categories')
    })

    try {
      const params: Record<string, number | string> = {
        limit: this.requestSettingsCategories.limit,
        offset: this.requestSettingsCategories.offset,
      }
      if (this.filtersQ[page]?.trim()) {
        params.q = this.filtersQ[page]
      }

      const { data } = await GlobalService.getFilters('categories', { params })
      runInAction(() => {
        this.isEndCategoryList =
          data.length < this.requestSettingsCategories.limit
        this.requestSettingsCategories.offset +=
          this.requestSettingsCategories.limit
        if (action === 'loadMore' && this.filtersBySelect.categories) {
          this.filtersBySelect.categories = [
            ...this.filtersBySelect.categories,
            ...data,
          ]
        } else {
          this.filtersBySelect.categories = data
        }
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('categories')
      })
    }
  }
}
export const globalStore = new GlobalModel()

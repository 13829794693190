/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useStore } from '~/app/store'
import { UserEdit } from '~/entities/users/user-edit'
import { formatErrorForToast, usePageTitle, useToast } from '~/shared/lib'
import { ArrowLeftSVG, Button, IconSave, PageTitle } from '~/shared/ui'
import Spinner from '~/shared/ui/spinner'

export const UserEditFeature = observer(() => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const { loaders, setRoles, editedUser } = usersStore
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  usePageTitle({
    title: 'Редактировать профиль',
  })

  const handleSave = async (e: any) => {
    try {
      setIsSubmitting(true)
      usersStore.tempHandlers.forEach((handler) => handler(e))

      await setRoles(
        id,
        editedUser?.roles.map((el) => el.name),
      )

      toast.open('Роли успешно отредактированы!', 'success')
    } catch (error: any) {
      toast.open(formatErrorForToast(error), 'danger')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center pb-6">
        <div
          className="flex items-center w-full md:w-auto max-w-md cursor-pointer"
          onClick={() => navigate('/settings')}
        >
          <div className="pr-4">
            <ArrowLeftSVG color="#707070" size="30" />
          </div>

          <PageTitle
            title="Редактировать профиль"
            size="text-[24px]"
            color="text-[#242424]"
          />
        </div>

        <Button intent="success" onClick={handleSave}>
          {loaders.setRoles || isSubmitting ? <Spinner /> : <IconSave />}
          {t('save')}
        </Button>
      </div>
      <UserEdit />
    </div>
  )
})

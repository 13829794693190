import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import { ListUser, LoginResponse } from './types'

export class AuthService {
  static login(data: unknown) {
    return apiService.post<LoginResponse>('/auth/login', data)
  }

  static logout(): Promise<{ data: []; status: boolean }> {
    return apiService.post('/auth/logout')
  }

  static getUsers(): Promise<AxiosResponse<ListUser[]>> {
    return apiService.get<AxiosResponse<ListUser[]>>('/users')
  }

  static getUsersById(id: string): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>(`/users/${id}`)
  }

  static loadMoreUsers(
    params: AxiosRequestConfig,
  ): Promise<AxiosResponse<ListUser[]>> {
    return apiService.get<AxiosResponse<ListUser[]>>('/users', params)
  }
}

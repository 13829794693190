import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import css from 'classnames'
import { MultiValue } from 'react-select'
import { CustomSelect, SelectOption, ToolTip, SVGReplay } from '~/shared/ui'
import { Props } from './types'
import { Input } from '~/shared/ui/input'

export const ProductsFilters: FC<Props> = ({
  manufacturerData = [],
  categoriesData = [],
  optionsData = [],
  changeFilters,
  statuses = [],
  clearAllFilters,
  isEmptyFilters,
  getSelectActiveData,
  onChangeProductInputFilters,
  searchName,
  searchSku,
  vendorCode,
  handleChangeSelectInput,
  setFiltersQ,
  findCategories,
  categoryLoading,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center flex-grow gap-2 flex-wrap  md:flex-nowrap">
      <div className="w-[300px]">
        <Input
          label={`${t('name')}`}
          name="name"
          id="name"
          value={searchName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeProductInputFilters?.(e.target.value, 'q')
          }
          labelType="floating"
          renderIconEnd={
            <ToolTip
              tooltipTitle={t('defaultToolTipTitle')}
              tooltipText={t('defaultToolTipText')}
            />
          }
        />
      </div>

      <div className="w-[300px]">
        <Input
          label={t('vendorCode')}
          name="vendorCode"
          id="vendorCode"
          value={vendorCode}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeProductInputFilters?.(e.target.value, 'vendorCode')
          }
          labelType="floating"
        />
      </div>

      <div className="w-[300px]">
        <Input
          label={t('barCode')}
          name="sku"
          id="sku"
          value={searchSku}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeProductInputFilters?.(e.target.value, 'sku')
          }
          labelType="floating"
        />
      </div>

      <div className="w-[300px]">
        <CustomSelect
          options={manufacturerData}
          value={getSelectActiveData('manufacturers')}
          onChange={(value) =>
            changeFilters('manufacturers', value as MultiValue<SelectOption>)
          }
          onInputChange={(e) => handleChangeSelectInput?.('manufacturer', e)}
          isMulti
          placeholder={t('manufacturers')}
          closeMenuOnSelect={false}
        />
      </div>

      <div className="w-[300px]">
        <CustomSelect
          options={categoriesData}
          value={getSelectActiveData('categories')}
          onChange={(value) =>
            changeFilters('categories', value as MultiValue<SelectOption>)
          }
          placeholder={t('categories')}
          onInputChange={(e) => setFiltersQ(e)}
          isMulti
          onMenuScrollToBottom={() => findCategories('loadMore')}
          isLoading={categoryLoading}
        />
      </div>

      <div className="w-[300px]">
        <CustomSelect
          options={statuses}
          value={getSelectActiveData('statuses')}
          onChange={(value) => {
            changeFilters('statuses', value as MultiValue<SelectOption>)
          }}
          placeholder={t('statuses')}
          isMulti
        />
      </div>

      <div className="w-[300px]">
        <CustomSelect
          options={optionsData}
          value={getSelectActiveData('options')}
          onChange={(value) =>
            changeFilters('options', value as MultiValue<SelectOption>)
          }
          placeholder={t('options')}
          onInputChange={(e) => handleChangeSelectInput?.('options', e)}
          isMulti
        />
      </div>

      <div
        onClick={() => (isEmptyFilters ? {} : clearAllFilters())}
        className={css(
          'min-w-[140px] flex items-center gap-2 cursor-pointer text-gray-700',
          {
            'text-slate-300 cursor-not-allowed': isEmptyFilters,
          },
        )}
        style={{ color: isEmptyFilters ? '#c7c7c7' : '' }}
      >
        <SVGReplay />
        {t('clearAll')}
      </div>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, CrossRemoveSVG, DeleteSVG, PageTitle } from '~/shared/ui'
import { ModalWindow } from '~/shared/ui/ModalWindow'
import { PropsModalWindow } from '~/shared/ui/ModalWindow/type'
import { formatErrorForToast, useToast } from '~/shared/lib'
import { useStore } from '~/app/store'
import Spinner from '~/shared/ui/spinner'

export const ModalDeleteUser: FC<PropsModalWindow> = observer(
  ({ isOpen, onClose, id }) => {
    const { t } = useTranslation()
    const toast = useToast()
    const { usersStore } = useStore()
    const navigate = useNavigate()
    const { loaders, deleteUser } = usersStore

    return (
      <ModalWindow onClose={onClose} isOpen={isOpen} maxWidth>
        <div className="flex  justify-between py-3 w-[400px]">
          <PageTitle
            title="Продолжить удаление пользователя?"
            size="text-[20px] ml-8"
          />
          <CrossRemoveSVG onChange={onClose} size="24" />
        </div>

        <div className="flex items-center justify-center p-6 space-x-2 ">
          <Button intent="secondary" onClick={onClose} type="submit">
            {t('Cancel2')}
          </Button>
          <Button
            intent="danger"
            disabled={loaders.userDelete}
            onClick={async (e) => {
              try {
                await deleteUser(id as string)
                toast.open('Пользователь успешно удален!', 'success')
                navigate('/settings')
              } catch (error: any) {
                toast.open(formatErrorForToast(error), 'danger')
                navigate('/settings')
              }

              onClose(e)
            }}
          >
            {loaders.userDelete ? <Spinner /> : <DeleteSVG color="white" />}

            {t('delete')}
          </Button>
        </div>
      </ModalWindow>
    )
  },
)

import { User } from '~/shared/api/services/users/types'

export const columns = [
  { header: 'name', accessorKey: 'name' },
  { header: 'email', accessorKey: 'email' },
  {
    header: 'role',
    accessorKey: 'roles',
    cell: ({ row }: { row: { original: User } }) => {
      const roles = row.original.roles.map((role) => role?.name).join(', ')
      return roles
    },
  },
]

interface MagniferSVGProps {
  size: string
}

export const MagniferSVG = (props: MagniferSVGProps) => {
  const { size } = props

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="search"
        d="M19.01 20.04L13.26 14.29C12.76 14.7 12.18 15.03 11.53 15.26C10.88 15.49 10.21 15.61 9.51 15.61C7.8 15.61 6.36 15.02 5.17 13.84C3.99 12.65 3.4 11.21 3.4 9.5C3.4 7.79 3.99 6.34 5.17 5.16C6.36 3.97 7.8 3.38 9.51 3.38C11.22 3.38 12.67 3.97 13.85 5.16C15.04 6.34 15.63 7.79 15.63 9.5C15.63 10.21 15.51 10.89 15.27 11.54C15.03 12.19 14.71 12.76 14.31 13.24L20.09 19.02C20.23 19.15 20.3 19.32 20.3 19.52C20.3 19.71 20.22 19.89 20.06 20.05C19.92 20.19 19.74 20.26 19.54 20.26C19.33 20.26 19.16 20.19 19.01 20.04ZM9.51 14.11C10.8 14.11 11.89 13.66 12.79 12.77C13.68 11.87 14.13 10.78 14.13 9.5C14.13 8.21 13.68 7.12 12.79 6.22C11.89 5.33 10.8 4.88 9.51 4.88C8.23 4.88 7.13 5.33 6.24 6.22C5.35 7.12 4.9 8.21 4.9 9.5C4.9 10.78 5.35 11.87 6.24 12.77C7.13 13.66 8.23 14.11 9.51 14.11Z"
        fill="#707070"
      />
    </svg>
  )
}

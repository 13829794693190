import { FC } from 'react'
import { ButtonProps } from './types'
import { button } from './config'
import { Spinner } from '../spinner'

export const Button: FC<ButtonProps> = ({
  className,
  intent,
  size,
  type = 'button',
  onClick,
  children,
  disabled = false,
  isLoading = false,
  hasBlueDot = false,
  badgeContent,
  badgeContentMarketplace,
}) => {
  const badgeClass =
    typeof badgeContentMarketplace === 'string' &&
    badgeContentMarketplace[0] === '-'
      ? 'bg-red-500 text-white'
      : 'bg-white text-[#242424] border border-gray-300'

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={!!(isLoading || disabled)}
      className={button({ intent, size, className })}
      style={{ position: 'relative' }}
    >
      {isLoading ? <Spinner /> : null}
      {children}
      {hasBlueDot && (
        <span className="absolute top-[5px] right-[5px] w-[7px] h-[7px] rounded-[50%] bg-[#2563eb]" />
      )}
      {badgeContent && (
        <span className="absolute bottom-[35px] left-[117px] flex items-center justify-center bg-white text-[#242424] text-[11px] min-w-[22px] h-[20px] rounded-full border border-gray-300">
          {badgeContent}
        </span>
      )}
      {badgeContentMarketplace && (
        <span
          className={`absolute bottom-[35px] left-0 flex items-center justify-center ${badgeClass} text-[11px] min-w-[65px] px-1 h-[20px] rounded-full whitespace-nowrap translate-x-[74px]`}
        >
          {badgeContentMarketplace}
        </span>
      )}
    </button>
  )
}

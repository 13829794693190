import { useTranslation } from 'react-i18next'
import { usePageTitle } from '~/shared/lib'
import { UsersTableWidget } from '~/widgets'

const Users = () => {
  const { t } = useTranslation()

  usePageTitle({
    title: t('users'),
  })

  return <UsersTableWidget />
}

export default Users

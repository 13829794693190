import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import css from 'classnames'

import { useEffect } from 'react'
import { LogoutButton } from '~/features/auth'
import { UserInfo, SVGSetting } from '~/shared/ui'
import { useStore } from '~/app/store'

export const NavUser = observer(() => {
  const {
    authStore: { user }, // при логине есть только эти данные пользователя
    usersStore: { currentUser, findUserByEmail, loaders },
  } = useStore()

  useEffect(() => {
    // получаем id пользователя для дальнейших действий ( возможно стоит добавить ручку бека "/me")
    if (!currentUser && user?.email && !loaders.findUser) {
      // findUserByEmail(user.email)
    }
  }, [user?.email, findUserByEmail, currentUser, loaders.findUser])

  return (
    <div className="flex items-center md:gap-6 px-2 justify-between">
      <div className="flex items-center gap-4">
        <NavLink
          to="/settings"
          className={({ isActive }) =>
            css(
              '"text-xl flex items-start justify-center relative group text-gray-900"',
              { '[&>span]:opacity-100 [&>span]:w-full': isActive },
            )
          }
        >
          <span className="icon hidden md:block absolute  opacity-0 group-hover:opacity-100 group-hover:w-full transition-all bottom-[-10px] left-0 right-0 w-0 bg-blue-600 h-[3px] rounded" />
          <SVGSetting />
        </NavLink>
        <UserInfo
          name={currentUser?.name}
          email={user?.email}
          id={currentUser?.id}
        />
      </div>
      <LogoutButton />
    </div>
  )
})

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useIntersectionObserver, useUpdateEffect } from 'usehooks-ts'
import { ChangeEvent, useEffect, useMemo, useRef } from 'react'
import { TableContextMenu, debounce } from '~/shared/lib'
import { TableContainer } from '~/shared/ui'
import { columns } from './lib/config'
import { useStore } from '~/app/store'
import GoToTop from '~/features/scroll-to-top'
import { Input } from '~/shared/ui/input'
import { ListUser } from '~/shared/api/services/users/types'

export const UsersTableWidget = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { usersStore } = useStore()
  const {
    getUsersById,
    usersList,
    q,
    getUsers,
    setQ,
    isMoreLoading,
    isListLoading,
  } = usersStore

  const handleRowClick = (row: object) => {
    if ('id' in row) {
      getUsersById(row.id as string)
      navigate(`/users/${row.id}/edit`)
    }
  }

  const debouncedQuery = useMemo(() => {
    return debounce((arg) => {
      const action = arg as 'q' | 'loadMore' | 'init'
      return getUsers(action)
    }, 1000)
  }, [getUsers])

  useEffect(() => {
    if (q) {
      debouncedQuery('q')
    }
  }, [q, debouncedQuery])

  const tableBottom = useRef<HTMLDivElement>(null)

  const entry = useIntersectionObserver(tableBottom, {})
  const isVisible = useMemo(
    () => !!entry?.isIntersecting,
    [entry?.isIntersecting],
  )

  const contextMenu: TableContextMenu<ListUser>[] = [
    {
      text: t('edit'),
      handler: (row) => {
        getUsersById(row.id as string)
        navigate(`/users/${row.id}/edit`)
      },
    },
  ]

  const anchor = useMemo(() => <div ref={tableBottom} />, [])

  useUpdateEffect(() => {
    if (isVisible) {
      getUsers('loadMore')
    }
  }, [isVisible])

  return (
    <>
      <Input
        id="usersQ"
        filterIcon={!q.length}
        size="small"
        value={q}
        crossRemove={q.length}
        labelType="floating"
        label={`${t('name')},  ${t('email')}`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setQ(e.target.value || '')
          if (!e.target.value) {
            debouncedQuery('init')
          }
        }}
        className="rounded-md border border-gray-300"
      />
      <div className="mt-10">
        <TableContainer
          fixedWidth
          columns={columns}
          data={usersList}
          loadMore={isMoreLoading}
          isLoading={isListLoading}
          onRowClick={(row) => handleRowClick(row)}
          contextMenu={contextMenu}
        />
        {!isListLoading && anchor}
      </div>
      <div className="fixed bottom-8 left-7">
        <GoToTop />
      </div>
    </>
  )
})

import { FC } from 'react'
import css from 'classnames'
import { Link } from 'react-router-dom'
import { Props } from './types'

export const UserInfo: FC<Props> = ({ email, name, className, id }) => {
  return (
    <div className={css('flex items-center space-x-3 w-full ', className)}>
      <Link to={id ? `/profile/${id}/edit` : '/settings'} className="px-1">
        <div className="font-medium">
          <h4 className="text-sm text-gray-950 m-0">{name}</h4>
          <p className="text-xs text-gray-500">{email}</p>
        </div>
      </Link>
    </div>
  )
}

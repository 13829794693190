/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStore } from '~/app/store'
import { RoleEdit } from '~/entities/roles/role-edit'
import { formatErrorForToast, usePageTitle, useToast } from '~/shared/lib'
import {
  ArrowLeftSVG,
  Button,
  DeleteSVG,
  IconSave,
  PageTitle,
} from '~/shared/ui'
import Spinner from '~/shared/ui/spinner'
import { ModalDeleteRole } from './modal-delete'

export const RoleEditFeature = observer(() => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const [openModal, setOpenModal] = useState(false)
  const { loaders, permissions, getPermission, updateRole, roleById } =
    usersStore
  const toast = useToast()

  const navigate = useNavigate()

  useEffect(() => {
    if (!permissions) {
      getPermission()
    }
  }, [getPermission, permissions])
  usePageTitle({
    title: 'Редактировать роль',
  })
  return (
    <div>
      <div className="flex justify-between items-center pb-6">
        <div
          className="flex items-center w-full md:w-auto max-w-md cursor-pointer"
          onClick={() => navigate('/settings')}
        >
          <div className="pr-4">
            <ArrowLeftSVG color="#707070" size="30" />
          </div>

          <PageTitle
            title="Редактировать роль"
            size="text-[24px]"
            color="text-[#242424]"
          />
        </div>
        <div className="flex gap-3">
          <Button
            intent="danger"
            onClick={() => {
              setOpenModal(true)
            }}
          >
            {loaders.roleDelete ? <Spinner /> : <DeleteSVG color="white" />}

            {t('delete')}
          </Button>
          <Button
            intent="success"
            onClick={async () => {
              try {
                await updateRole()
                navigate('/settings')
                toast.open('Роль успешно отредактирована!', 'success')
              } catch (error: any) {
                toast.open(formatErrorForToast(error), 'danger')
              }
            }}
          >
            {loaders.roleEdit ? <Spinner /> : <IconSave />}

            {t('save')}
          </Button>
        </div>
      </div>
      <RoleEdit />
      <ModalDeleteRole
        isOpen={openModal}
        id={roleById?.id}
        onClose={(e) => {
          e.preventDefault()
          setOpenModal(false)
        }}
      />
    </div>
  )
})
